@import "./font.scss";
@import "./common";
@import "./pages.scss";
@import "./survey.scss";
@import './dx.light.compact.scss';



// Basic
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

a:visited {
  color: #000;
}

p {
  margin: 0;
}

li {
  list-style: none;
}

