
//Button
.btn {
  padding: 0 20px;
  font-size: 1.6rem;
  border-radius: 4px;
  border: none;

  svg {
    g {
      rect, path {
        fill: #fff;
      }
    }
  }

  .btn-tit {
    display: flex;
    align-items: center; 
  
    span {
      margin-left: 10px;
      font-size: 1.8rem;
    }
  }

  &.open {
    border-radius: 4px 4px 0 0;
  }

  &.w-max {
    width: 100%;
  }

  &.w-150 {
    width: 150px;
  }

  &.h-54 {
    height: 54px;
  }

  &.h-45 {
    height: 45px;
  }

  &.m-t-90 {
    margin-top: 90px;
  }

  &.c-main {
    color: #fff;
    background: #0072db;

    &:active {
      color: #fff;
      background: #005eb6;
    }
  }

  &.c-light {
    color: #fff;
    background: #039be5;

    &:active {
      color: #fff;
      background: #0391d8;
    }
  }

  &.c-dark {
    color: #fff;
    background: #0072db;

    &:active {
      color: #fff;
      background: #005eb6;
    }
  }

  &.flex-center {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.space-between {
      justify-content: space-between;
    }
  }

  &.slide {
    .minus {
      display: none;
    }

    &.active {
      .minus {
        display: inline;
      }

      .plus {
        display: none;
      }
    }
  }
}



//결과입력 로딩 팝업
.loading-wrap {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  align-items: center;
  justify-content: center;
  z-index: 999;

  &.result {
    background-color: rgba(255, 255, 255, 0.8);
    $color: #8c6ff0;
    $colorRight: #5628ee;
    $colorLeft: #23c4f8;

    .circle {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: #1e90ff;
      margin-bottom: 25px;
      position: relative;
    }

    .loading-cont {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .rowc {
      display: inline-block;
      margin-left: 2px;
    }

    .c1 {
      animation: c1 1.5s linear infinite;
    }

    .c2 {
      animation: c2 1.5s linear infinite;
      background-color: #3fd1ff;
    }

    @keyframes c1 {
      0% {
        transform: translateY(0px) scale(1);
      }
      25% {
        transform: translateY(12px) scale(1.5);
        background-color: #0c87ff;
        z-index: 10;
      }
      50% {
        transform: translateY(34px) scale(1);
      }
      75% {
        transform: translateY(12px) scale(0.6);
        background-color: #3a9eff;
        z-index: 1;
        opacity: 0.1;
      }
      100% {
        transform: translateY(0px) scale(1);
      }
    }

    @keyframes c2 {
      0% {
        transform: translateY(0px) scale(1);
      }
      25% {
        transform: translateY(-12px) scale(0.6);
        background-color: #75deff;
        z-index: 1;
        opacity: 0.1;
      }
      50% {
        transform: translateY(-34px) scale(1);
      }
      75% {
        transform: translateY(-12px) scale(1.5);
        background-color: #0fc3ff;
        z-index: 10;
      }
      100% {
        transform: translateY(0px) scale(1);
      }
    }

    .row2 .c1 {
      animation-delay: 0.1s;
    }
    .row2 .c2 {
      animation-delay: 0.1s;
    }
    .row3 .c1 {
      animation-delay: 0.22s;
    }
    .row3 .c2 {
      animation-delay: 0.22s;
    }
    .row4 .c1 {
      animation-delay: 0.37s;
    }
    .row4 .c2 {
      animation-delay: 0.37s;
    }
    .row5 .c1 {
      animation-delay: 0.49s;
    }
    .row5 .c2 {
      animation-delay: 0.49s;
    }
    .row6 .c1 {
      animation-delay: 0.67s;
    }
    .row6 .c2 {
      animation-delay: 0.67s;
    }
    .row7 .c1 {
      animation-delay: 0.89s;
    }
    .row7 .c2 {
      animation-delay: 0.89s;
    }
    .row8 .c1 {
      animation-delay: 0.95s;
    }
    .row8 .c2 {
      animation-delay: 0.95s;
    }
    .row9 .c1 {
      animation-delay: 1.2s;
    }
    .row9 .c2 {
      animation-delay: 1.2s;
    }
    .row10 .c1 {
      animation-delay: 1.45s;
    }
    .row10 .c2 {
      animation-delay: 1.45s;
    }
    .row11 .c1 {
      animation-delay: 1.62s;
    }
    .row11 .c2 {
      animation-delay: 1.62s;
    }
    .row12 .c1 {
      animation-delay: 1.88s;
    }
    .row12 .c2 {
      animation-delay: 1.88s;
    }
    .row13 .c1 {
      animation-delay: 2s;
    }
    .row13 .c2 {
      animation-delay: 2s;
    }

    .load-txt {
      text-align: center;
      font-size: 1.4rem;
    }
  }

  .loading-up {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 140px;
    background: #fff;
    border-radius: 5px;
    border: none;
    flex-direction: column;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

    .spinner {
      margin-top: 10px;
    }

    .loading-txt {
      margin-top: 24px;
      font-size: 1.8rem;
    }
  }
}
