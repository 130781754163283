//페이지 공통 스타일
.page-wrap {
    padding: 20px;
    background: #f8f8f8;
  
    .page-tit {
      display: flex;
      margin:10px 0 30px;
      color: #4b4b4b;
      font-size: 2.2rem;
      font-weight: 500;
      align-items: center;
    }
  
    .toggle-info {
      margin: 0 0 30px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  
      .answer-row {
        overflow: hidden;
        display: flex;
        padding: 10px 20px;
        flex-direction: column;
        margin-bottom: 0;
        background: #fff;
        border: 1px solid #039be5;
        border-radius: 0 0 4px 4px;
      
        li {
          display: flex;
          width: 100%;
          flex-direction: column;
      
          &+ li {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #c9c9c9;
          }
      
          .row-header {
            display: flex;
            margin-top: 10px;
            padding-left: 10px;
            color: #039be5;
            font-size: 16px;
            font-weight: 500;
            justify-content: flex-start;
            // border-right: 1px solid #039be5;
            align-items: center;
          }
      
          .row-rows {
            width: 100%;
            display: flex;
            padding: 5px 0;
            flex-wrap: wrap;
            flex-direction: row;
              
            div {
              display: flex;
              width: calc(50%);
              height: 80px;
              padding: 0 15px;
              flex-direction: column;
              justify-content: center;
      
              span {
                color: rgb(59, 59, 59);
                font-size: 1.8rem;
                font-weight: 500;
      
                &.answer-subject {
                  color: #7c7c7c;
                  font-size: 1.4rem;
                  margin-bottom: 5px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  
    .food-est-list {
      margin-top: 30px;
  
      .slide-btn-wrap {
        width: 100%;
        margin: 0 auto;
        border-bottom: 2px solid #d8d8d8;
  
        .slide-btn {
          height: 45px;
          padding-left: 10px;
          color: #676767;
          font-size: 18px;
          letter-spacing: -.5px;
          border: none;
          background: none;
          cursor: pointer;
  
          .minus {
            display: none;
          }
  
          &.active {
            .minus {
              display: inline;
            }
  
            .plus {
              display: none;
            }
          }
        }
      }
  
      .food-est-wrap {
        overflow: hidden;
        display: flex;
        height: auto;
        flex-direction: column;
  
          .food-est-init {
            overflow: hidden;
            border-radius: 6px;
            transition: opacity 0.3s ease-out;
            cursor: pointer;
  
            &.invisible {
              display: none;
            }

            &.visible {
              &+ .visible {
                margin-top: 20px;
              }
            }
  
            &.active {
              max-height: fit-content;
              flex-direction: column;
              transition: opacity 0.3s ease-in;
              background: #fff;
  
              .food-est-level {
                .food-est-arrow {
                  transform: rotate(180deg);
                  transition: all 0.3s ease;
                }
  
                .level-exp {
                  display: block;
                  margin-top: 14px;
                  margin-bottom: 0 !important;
                  line-height: 2.2rem;
                  font-size: 1.5rem;
                }
              }
  
            .diet-list {
              display: flex;
              width: 100%;
              padding: 20px;
              flex-direction: column;
              align-items: center;
            }
  
            .exp-wrap {
              display: flex;
              width: 100%;
              height: auto;
  
              &+ .exp-wrap {
                margin-top: 60px;
                padding-top: 40px;
                border-top: 1px solid #DFDFDF;
              }
        
              .no-diet-data {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
        
                .message-txt {
                  margin-top: 10px;
                  font-size: 1.3rem;
                }
              }
        
              .diet-img {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: center;
        
                img {
                  width: 100%;
                  height: 260px;
                  margin-bottom: 10px;
                  background: #f6f6f6;
                  border-radius: 4px;
                  object-fit: cover;
                }
              }
        
              .diet-info {
                width: 100%;
                margin: 20px 0 0;
                height: auto;
        
                .info-date {
                  display: flex;
                  justify-content: space-between;
        
                  .date-tit {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: space-between;
                  }
        
                  .date-txt {
                    color: #242424;
                    font-size: 1.6rem;
                    font-weight: 500;
                    border-bottom: none;
        
                    svg {
                      margin-bottom: 4px;
                      vertical-align: middle;
        
                      g {
                        path {
                          fill: #242424;
                        }
                      }
                    }
                  }
        
                  .diet-level {
                    display: flex;
                    margin-top: 10px;
                    justify-content: flex-end;
        
                    .level-icon {
                      display: inline-block;
                      width: 24px;
                      height: 24px;
                    }
        
                    .level-txt {
                      width: 40px;
                      align-items: center;
                      text-align: center;
                      font-size: 1.2rem;
                      padding: 1px;
                      margin-left: 4px;
                      color: #fff;
                      font-weight: 500;
                      line-height: 24px;
                      border-radius: 4px;
                    }
        
                    &.good {
                      .level-icon {
                        background: url('./icons/good.png') no-repeat;
                        background-size: contain;
                      }
        
                      .level-txt {
                        background: #00CAB2;
                      }
                    }
        
                    &.fine {
                      .level-icon {
                        background: url('./icons/fine.png') no-repeat;
                        background-size: contain;
                      }
        
                      .level-txt {
                        background: #33B900;
                      }
                    }
        
                    &.worried {
                      .level-icon {
                        background: url('./icons/worried.png') no-repeat;
                        background-size: contain;
                      }
        
                      .level-txt {
                        background: #febb01;
                      }
                    }
        
                    &.caution {
                      .level-icon {
                        background: url('./icons/caution.png') no-repeat;
                        background-size: contain;
                      }
        
                      .level-txt {
                        background: #FF8A01;
                      }
                    }
        
                    &.bad {
                      .level-icon {
                        background: url('./icons/bad.png') no-repeat;
                        background-size: contain;
                      }
        
                      .level-txt {
                        background: #EC0C00;
                      }
                    }
                  }
                }
        
                .food-list {
                  margin: 20px 0;
                  padding: 10px 20px;
                  background: #fff;
                  border: 1px solid #ececec;
                  border-radius: 4px;
                  color: #464646;
                  font-size: 1.4rem;
                  line-height: 2.2rem;
                  background: #f9faff;
                }
        
                .info-score {
                  display: flex;
                  width: 100%;
                  padding: 0 5%;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
  
                  .nut-1 {
                    display: flex;
                    margin: 20px 0 ;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                  }
        
                  .nut-1, .nut-2 {
                    width: 100%;
                    padding: 0;
                  }
        
                  // .nut-2 {
                  //   width: 48%;
                  //   margin-left: calc(1% - 1px);
                  //   padding-left: 20px;
                  // }
        
                  .line {
                    display: inline-block;
                    width: 1px;
                    height: 16px;
                    background: none;
                  }
        
                  .nut-item {
                    display: flex;
                    width: 48%;
                    margin-bottom: 30px;
                    flex-direction: column;
                    justify-content: flex-start;
        
                    .nut-i-tit {
                      color: #616161;
                      font-size: 1.3rem;
                    }
        
                    .nut-i-cont {
                      margin-top: 5px;
                      color: #000;
                      font-size: 1.6rem;
                      font-weight: 500;
        
                    }
        
                    .nut-i-level {
                      width: 34px;
                      height: 18px;
                      margin-left: 5px; 
                      color: #fff;
                      font-size: 0.9rem;
                      text-align: center;
                      border-radius: 4px;
        
                      &.scale {
                        width: unset;
                        height: unset;
                        padding: 2px 8px;
                        font-size: 1rem;
                        font-weight: 500;
                      }
        
                      &.more {
                        background: #0077E4;
                      }
        
                      &.less {
                        background: #f16744;
                      }
                    }
                  }
                }
        
                .diet-result {
                  display: flex;
                  padding: 15px 20px;
                  margin-top: 20px;
                  color: #616161;
                  font-size: 1.2rem;
                  letter-spacing: -0.4px;
                  background: #f9faff;
                  border-radius: 10px;
                  align-items: center;
                  flex-wrap: wrap;
                  word-break: nowrap;
                  justify-content: center;
        
                  .diet-type {
                    margin: 0px 5px;
                    padding: 1px 8px;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: 500;
                    background: #0077E4;
                    border-radius: 4px;
                    white-space: nowrap;
                  word-break: nowrap;
                  }
                }
              }
            }
  
              .info-score {
                justify-content: flex-start;
              }
  
              .bloodlevel-change {
                display: flex;
                padding: 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #fff;
                border: 1px solid #ececec;
                border-radius: 4px;
              }
  
              .nut-2 {
                display: flex;
                width: 90%;
                margin-top: 20px;
                flex-direction: column;
  
                .nut-item {
                  display: flex;
                  width: 100%;
                  align-items: flex-start;
                  flex-direction: column;
  
                  & + .nut-item {
                    margin-top: 20px;
                  }
  
                  .nut-i-tit {
                    color: #616161;
                    font-size: 1.3rem;
                  }
  
                  .nut-i-cont {
                    margin-top: 5px;
                    margin-left: auto;
                    font-size: 1.6rem;
                    font-weight: 500;
                    color: #000;
                  }
  
                  .nut-i-level {
                    width: 34px;
                    height: 18px;
                    margin-left: 10px;
                    color: #fff;
                    font-size: 0.9rem;
                    text-align: center;
                    border-radius: 4px;
  
                    &.scale {
                      width: unset;
                      height: unset;
                      padding: 2px 8px;
                      font-size: 1.4rem;
                      font-weight: 500;
                    }
  
                    &.more {
                      background: #0077E4;
                    }
      
                    &.less {
                      background: #f16744;
                    }
                  }
                }
              }
  
              &.good {
                .diet-list {
                  border: 1px solid #00CAB2;
                  border-radius: 0 0 6px 6px;
                }
              }
  
              &.fine {
                .diet-list {
                  border: 1px solid #33B900;
                  border-radius: 0 0 6px 6px;
                }
              }
  
              &.worried {
                .diet-list {
                  border: 1px solid #febb01;
                  border-radius: 0 0 6px 6px;
                }
              }
  
              &.caution {
                  .diet-list {
                    border: 1px solid #FF8A01;
                    border-radius: 0 0 6px 6px;
                  }
              }
  
              &.bad {
                  .diet-list {
                    border: 1px solid #EC0C00;
                    border-radius: 0 0 6px 6px;
                  }
              }
            }
  
            &.good {
              .food-est-level {
                background: #00CAB2;
                border: 1px solid #00cab2;
  
              .level-icon {
                background: url('./icons/good.png') no-repeat;
                background-size: contain;
              }
              }
            }
  
            &.fine {
              .food-est-level {
                background: #33B900;
                border: 1px solid #33B900;
  
              .level-icon {
                background: url('./icons/fine.png') no-repeat;
                background-size: contain;
              }
              }
            }
  
            &.worried {
              .food-est-level {
                background: #febb01;
                border: 1px solid #febb01;
  
              .level-icon {
                background: url('./icons/worried.png') no-repeat;
                background-size: contain;
              }
              }
            }
  
            &.caution {
              .food-est-level {
                background: #FF8A01;
                border: 1px solid #FF8A01;
  
                .level-icon {
                  background: url('./icons/caution.png') no-repeat;
                  background-size: contain;
                }}
            }
  
            &.bad {
              .food-est-level {
                background: #EC0C00;
                border: 1px solid #EC0C00;
  
                .level-icon {
                  background: url('./icons/bad.png') no-repeat;
                  background-size: contain;
                }}
            }
  
            .food-est-level {
              display: flex;
              padding: 10px 20px 10px;
              color: #fff;
              flex-direction: column;
  
              .est-level {
                display: flex;
                font-size: 18px;
                align-items: center;
                font-weight: 600;
                justify-content:space-between;
  
                  &> span {
                    display: flex;
                    align-items: center; 
  
                    .level-icon {
                      display: inline-block;
                      width: 24px;
                      height: 24px;
                      margin-right: 5px;
                    }
                  }
  
                  .food-est-arrow {
                    transition: all 0.3s ease;
  
                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
              }
  
              .level-exp {
                display: none;
                margin-bottom: 5px;
              }
            }
  
            .exp-wrap {
              display: none;
            }
          }
      }
    }
  
    .page-content-wrap {
      padding: 20px;
      background: #fff;
      border-radius: 4px;
  
      &+ .page-content-wrap {
        margin-top: 20px;
      }
  
      .page-content-tit {
        display: flex;
        width: 100%;
        margin-top: 4px;
        color: #4b4b4b;
        font-size: 1.8rem;
        font-weight: 500;
        align-items: top;
    
        svg {
          height: 26px;
          width: 26px;
          // margin-bottom: 4px;
        }
  
        span {
          width: calc(100% - 33px);
          margin-left: 5px;
          margin-top: 2px;
        }
      }
  
      .page-content {
        &+ .page-content {
          margin-top: 80px;
        }
  
        .result-score-img {
          padding: 0 20px 0;
  
          img {
            width: 100%;
          }
        }
  
        .circle-gauge {
          margin-bottom: 30px;
        }
  
        .content-img {
          display: flex;
          width: 100%;
  
          img {
            width: 92%;
  
            &.fork {
              width: 8%;
            }
          }
        }
  
        .bar {
          &.tit {
            font-size: 1.6rem;
            font-weight: 500;
            margin: 30px 0 12px;
          }
  
          &.sub-tit {
            display: flex;
            margin: 60px 0 0;
            padding: 0 10px;
            font-size: 1.6rem;
            font-weight: 500;
            align-items: flex-start;

            svg {
              width: 24px;
            }

            span {
              width: calc(100% - 24px);
            }
          }
  
          &.txt {
            display: flex;
            margin: 20px 0;
            padding: 20px;
            color: #676767;
            font-size: 1.6rem;
            justify-content: center;
            background: #f9faff;
            border-radius: 4px;
          }
  
          &.txt-r {
            display: flex;
            padding: 0 10px;
            font-size: 2rem;
            justify-content: flex-end;
  
            &+ .sub-tit {
              margin-top: 20px;
            }
  
            span {
              font-weight: 600;
              color: #039be5;
            }
          }
  
          &.cluster {
            display: flex;
            flex-direction: column;
            align-items: center;
  
            img {
              width: 90%;
            }
  
            span {
              font-size: 1.4rem;
            }
          }
  
          &.bullet {
            .chart-bullet {
              width: 100%;
            }

            .calculate {
              display: flex;

              .cal-suggest {
                display: flex;
                width: calc(50% - 5px);
                padding: 5px;
                color: #4a4a4a;
                font-size: 1.6rem;
                border: 1px solid #e7e7e7;
                border-radius: 4px;
                background: #f5f5f5;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
    
                .cal-tit {
                  border-color: rgba(#e7e7e7, 1);
                }
              }
    
              .cal-info {
                display: flex;
                width: calc(50% - 5px);
                margin-left: 10px;
                padding: 5px;
                color: #fff;
                font-size: 1.6rem;
                font-weight: 600;
                border-radius: 4px;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
    
                &.less {
                  background-color: #2295FF;
                }
    
                &.more {
                  background-color: #f16744;
                }
                
                .cal-tit {
                  border-color: rgba(#fff, .4);
                }
              }

              .cal-tit {
                display: inline-block;
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 1.4rem;
                text-align: center;
                border-bottom: 1px solid;
              }

              .cal-num {
                display: flex;
                height: 100%;
                padding: 10px 0;
                text-align: center;
                align-items: center;
                justify-content: center;
              }
            }
          }
  
          &.list {
            display: flex;
            height: auto;
            margin: 20px 0 60px;
            flex-direction: column;
            align-items: center;
    
            .bar-items {
              display: flex;
              width: 90%;
              flex-direction: column;
    
              &+ .bar-items {
                margin-top: 30px;
              }
    
              .item-tit {
                width: 100%;
                font-size: 1.4rem;
                font-weight: 400;
                // text-align: center;
              }
    
              .lineargauge {
                height: 40px;
                width: 100%;
                // padding: 0 10px;
                // background: #fff;
                // box-shadow: 0px 2px 6px 0px rgba(0,0,0,.1);
                border-radius: 4px;
    
                svg {
                  overflow: visible !important;
    
                  .dxg-value-indicator {
                    circle {
                      -webkit-filter: drop-shadow(0px 0px 10px rgba(50 50 50/0.3));
                      filter: drop-shadow(0px 0px 10px rgba(50 50 50/0.3));
                    }
                  }
                }
              }
            }
          }
  
          &.table {
            width: 90%;
            margin: 20px auto 0;
            border-spacing: 0;
            border-collapse: collapse;
  
            th {
              background: #f5f5f5;
              padding: 10px;
              font-size: 1.6rem; 
              border: 1px solid #e7e7e7;
              text-align: center;
            }
  
            td {
              padding: 10px;
              color: #676767;
              font-size: 1.6rem; 
              text-align: center;
              border: 1px solid #e7e7e7;
              background: #fff;
  
              &.on {
                background: #039be5;
                color: #fff;
                font-size: 1.8rem;
                font-weight: 600;
              }
            }
  
            &+ .result-exp {
              margin-top: 40px;
            }
          }

          &.bar-img {
            width: 100%;

            img {
              width: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
  
        .result-exp {
          padding: 10px;
          margin-top: 10px;
  
          p {
            color: #676767;
            font-size: 1.6rem;
            line-height: 2.6rem;
            letter-spacing: -.5px;
  
            &+ p {
              margin-top: 10px;
            }
  
            span {
              font-weight: 600;
              color: #039be5;
            }
          }
          
          .exp-name {
            font-size: 1.6rem;
            font-weight: 600;
            color: #000;
            
            &+ .exp-elem {
              margin-top: 6px;
            }
          }
  
          .exp-elem {
            padding: 15px;
            margin-top: 30px;
            color: #676767;
            font-size: 1.6rem;
            line-height: 2.6rem;
            background: #f9faff;
            border-radius: 4px;
  
            span {
              font-weight: 600;
              color: #000;
  
              &.highlight {
                color: #039be5;
              }
            }
  
            &+ .exp-name {
              margin-top: 30px;
            }
          }
  
          .result-txt {
            padding: 15px;
            margin-top: 10px;
            color: #3F4254;
            background: #f9faff;
            border-radius: 4px;
  
            &+ .result-txt {
              margin-top: 30px;
            }
  
            .exp-tit {
              display: flex;
              margin-bottom: 10px;
              font-size: 1.6rem;
              font-weight: 600;
              align-items: center;
  
              .svg-icon-lg {
                height: 24px;
                margin-right: 5px;
              }
            }
  
            p {
              font-size: 1.5rem;
            }
          }
        }
  
        .content-footer {
          padding: 15px;
          margin-top: 30px;
          color: #525252;
          background: #f3f3f3;
          border-radius: 4px;
  
          span {
            display: inline-block;
            margin-bottom: 5px;
            font-size: 1.5rem;
            font-weight: 500;
          }
  
          p {
            font-size: 1.5rem;
            line-height: 2.4rem;
  
            &+ p {
              margin-top: 6px;
            }
          }
        }
  
        .slide-btn-wrap {
          width: 100%;
          margin: 40px auto 0;
        }
    
        .exp-table-wrap {
          width: 100%;
          margin: 40px auto 0;
    
          table {
            font-size: 1.2rem;
            border-collapse: collapse;
    
            td {
              padding: 5px;
              border: 1px solid #e7e7e7;
    
              span {
                color: #039be5;
                font-weight: 600;
                cursor: pointer;
              }
            }
    
            th {
              text-align: center;
              background: #f5f5f5;
              border: 1px solid #e7e7e7;
            }
    
            tbody {
              .col-01 {
                width: 10%;
                text-align: center;
              }
    
              .col-03 {
                padding: 10px 20px;
    
                &.pc {
                  display: table-cell;
                }
    
                &.mobile {
                  display: none;
                }
              }
    
              .col-04 {
                width: 10%;
                text-align: center;
              }
            }
          }
        }
      }
  
      //상단 탭(연속혈당기반)
      .page-tabs-wrap {
        position: relative;
  
        .page-tabs {
          display: flex;
          margin: 0;
          align-items: flex-end;
  
          .page-tab-item {
            display: flex;
            width: 14%;
            height: 40px;
            color: #fff;
            font-size: 1.1rem;
            text-align: center;
            background: #ccc;
            border-radius: 10px 10px 0 0;
            align-items: center;
            justify-content: center;
            cursor: pointer;
  
            &.isActive {
              width: 24%;
              height: 50px;
              color: #3f3f3f;
              font-size: 1.4rem;
              font-weight: 600;
              background: #fff;
              box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  
              &:hover {
                background: #3f3f3f;
                background: #fff;
              }
            }
  
            &.food {
              background: #003668;
  
              &:hover {
                background: #002c55;
              }
            }
  
            &.blood {
              background: #005cb0;
  
              &:hover {
                background: #0053a1;
              }
            }
  
            &.canpro {
              background: #0072db;
  
              &:hover {
                background: #006dd3;
              }
            }
  
            &:hover {
              background: #fff;
              background: rgb(182, 182, 182);
              box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
            }
          }
  
          .result-summary {
            width: 130px;
            height: 42px;
            padding: 0;
            margin-left: auto;
            margin-bottom: 8px;
            font-size: 16px;
            background: #484848;
  
            a {
              display: inline-block;
              width: 130px;
              height: 42px;
              padding: 0.65rem;
              color: #fff;
            }
  
            &:hover {
              background: #3f3f3f;
  
              a {
                color: #fff;
              }
            }
  
            &:active {
              background: #2e2e2e;
  
              a {
                color: #fff;
              }
            }
          }
        }
      }
  
      .tabs-content {
        position: relative;
        padding: 40px;
        background: #fff;
        border-radius: 0 10px 10px 10px;
        box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.15);
      }
  
      .result {
        padding: 45px;
        width: 100%;
      }
  
  
      //Explane Wrap
      .exp-outer {
        width: 100%;
        border-radius: 5px;
  
        .exp-wrap {
          overflow: hidden;
          height: auto;
          font-size: 16px;
          vertical-align: middle;
  
          .exp-tit {
            width: 100%;
            height: 40px;
            padding-left: 5px;
            font-size: 20px;
            color: #676767;
          }
        }
  
        &+ .exp-outer {
          margin-top: 20px;
        }
      }
    }
  
    .survey-btn-wrap {
      display: flex;
      width: 100%;
      height: 85px;
      align-items: center;
      justify-content: center;
  
      a {
        display: inline-block;
        width: 100%;
        height: 45px;
        color: #fff;
        line-height: 45px; 
      }
    }
  }