//만족도 조사 페이지
.survey {
    .table-container {
      
      .section-tit {
        display: flex;
        height: auto;
        padding: 0 10px 6px;
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: 500;
        align-items: center;
      }
  
      .section-answer {
        display: flex;
        padding: 0 20px 80px;
        flex-direction: column;
  
        .fv-plugins-message-container {
          div {
            color: #f64e60;
            font-size: 0.9rem;
            font-weight: 400;
          }
        }
  
        .selection {
          display: flex;
          align-items: center;
  
          &+ .selection {
            margin-top: 20px;
          }
  
          &.column {
            flex-direction: column;
            align-items: flex-start;
  
            .answer_tail {
              margin-top: 6px;
              font-size: 1.4rem;
              color: rgb(83, 83, 83);
            }
          }
  
          .radio-select {
            width: 18px;
            height: 18px;
          }
  
          .checkbox {
            width: 16px;
            height: 16px;
          }
  
          label {
            width: calc(100% - 28px);
            margin: 0 0 0 10px;
            font-size: 16px;
          }
  
          .textbox {
            width: 30%;
            height: 35px;
            margin-left: 10px;
            padding: 5px 10px;
            background-color: #f4f6ff;
            border-radius: 4px;
            border: none;
  
            &:disabled {
              background: rgb(240, 240, 240);
              border: 1px solid rgb(224, 224, 224);
            }
          }
  
          &.etc {
            label {
              width: 60px;
            }
  
            .textbox {
              width: calc(100% - 100px);
              height: 35px;
              margin-left: 10px;
              padding: 5px 10px;
              background-color: #f4f6ff;
              border-radius: 4px;
              border: none;
    
              &:disabled {
                background: rgb(240, 240, 240);
                border: 1px solid rgb(224, 224, 224);
              }
            }
          }
        }
  
        &.text {
          padding: 0 0 60px;
  
          .selection {
            width: 100%; 
             
            .textbox {
              width: 100%;
              height: 40px;
              padding: 5px 10px;
              margin-left: 0;
              background-color: #f4f6ff;
              border-radius: 4px;
              border: none;
  
              &::placeholder {
                visibility: visible;
              }
  
              &:disabled {
                background: rgb(240, 240, 240);
                border: 1px solid rgb(224, 224, 224);
  
                &::placeholder {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
  
      .survey-submit-wrap {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }


  //설문완료 메세지 창
.message-wrap {
    display: flex;
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    .message {
      display: inline;
      background: #fff;
      border-radius: 10px;
      margin: 0 auto;
      padding: 40px 80px;
  
      span {
        display: inline-block;
        width: 100%;
        text-align: center;
  
        &.message-txt {
          margin-top: 10px;
          font-size: 1.6rem;
        }
      }
    }
  }
  