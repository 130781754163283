@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 200;
    src: url("./fonts/SpoqaHanSansNeo-Thin.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Thin.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Thin.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 300;
    src: url("./fonts/SpoqaHanSansNeo-Light.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Light.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Light.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 400;
    src: url("./fonts/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Regular.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Regular.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 500;
    src: url("./fonts/SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Medium.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Medium.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 600;
    src: url("./fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Bold.ttf") format("ttf")
}

* {
    letter-spacing: -0.2px;
    }

html {
    font-size: 10px;
    }

body {
    font-size: 1rem;
    font-weight: 400;
    font-family: Spoqa Han Sans;
    }